<template>
	<div class="app-body">
		<div class="a-flex-rsbc a-ptb-6">
			<span class="a-fs-16 a-fw-b">加盟商管理</span>
			<div class="a-flex-rcc">
				<el-button icon="el-icon-upload2" class="s-btn-exp">导出</el-button>
				<el-button icon="el-icon-plus" type="primary" class="s-btn-add a-ml-24" @click="handlerAddProject">加盟商</el-button>
			</div>
		</div>
		<el-card class="el-main">
			<le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
				<le-input-icon-search label="加盟商名称" v-model="pageParam.params.searchKey" placeholder="请输入加盟商名称" />
				<le-input-icon-search label="加盟商ID" v-model="pageParam.params.queryCompanyId" placeholder="请输入加盟商ID" />
				<le-select-local-search  label="签署状态" v-model="pageParam.params.type"
					:options="optionsProject" />
                <le-select-local-search  label="加盟类型" v-model="pageParam.params.companyType"
					:options="optionsCompanyType" />
				<le-input-district  label="省市区" :province.sync="pageParam.params.provinceCode"
					:city.sync="pageParam.params.cityCode" :area.sync="pageParam.params.districtCode">
				</le-input-district>
				<le-date-range label="创建时间" :minDate.sync="pageParam.params.startTime"
					:maxDate.sync="pageParam.params.endTime" />
			</le-search-form>
			<le-pagview ref="franchisee" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.franchiseeList">
				<el-table ref="franchiseeList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }">
					<el-table-column prop="companyId" label="加盟商ID" min-width="120"></el-table-column>
					<el-table-column prop="companyName" label="加盟商名称" min-width="150"></el-table-column>
					<el-table-column prop="statusText" label="生效状态" min-width="100"></el-table-column>
					<el-table-column prop="stationNum" label="站点数量" min-width="100"></el-table-column>
					<el-table-column prop="deviceNum" label="设备数量" min-width="100"></el-table-column>
					<el-table-column prop="deviceNum" label="加盟类型" min-width="100">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyType | initDic(optionsCompanyType) }}</span>
                        </template>
                    </el-table-column>
					<el-table-column prop="upCompanyName" label="上级代理商" min-width="150"></el-table-column>
					<el-table-column prop="underCompanyNum" label="代理商等级" min-width="100">
                        <template slot-scope="{ row }">
                            <span>{{ row.agencyLevel?row.agencyLevel:'-' }}</span>
                        </template>
                    </el-table-column>
					<el-table-column prop="underCompanyNum" label="套餐权限" min-width="140">
                        <template slot-scope="scope">
                            <div class="a-flex-rfsc" v-if="scope.row.companyType == 2">
                                <el-switch
                                    @change="changeMonthCardAuth(scope.row,scope.$index)"
                                    v-model="scope.row.monthCardAuth"
                                    :inactive-value="0"
                                    :active-value="1"
                                    active-color="#409EFF"
                                    inactive-color="#F56C6C">
                                </el-switch>
                                <span class="a-mlr-12" :class="scope.row.monthCardAuth?'a-c-blue':'a-c-error'">{{ scope.row.monthCardAuth?'已启用':'已禁用' }}</span>
                            </div>
                            <span v-else>-</span>
						</template>
                    </el-table-column>
                    <el-table-column prop="underCompanyNum" label="独立钱包" min-width="140">
                        <template slot-scope="scope">
                            <div class="a-flex-rfsc" v-if="scope.row.companyType != 3">
                                <el-switch
                                    @change="changeServiceProfit(scope.row,scope.$index)"
                                    v-model="scope.row.serviceProfit"
                                    :inactive-value="0"
                                    :active-value="1"
                                    active-color="#409EFF"
                                    inactive-color="#F56C6C">
                                </el-switch>
                                <span class="a-mlr-12" :class="scope.row.serviceProfit?'a-c-blue':'a-c-error'">{{ scope.row.serviceProfit?'已启用':'已关闭' }}</span>
                            </div>
                            <span v-else>-</span>
						</template>
                    </el-table-column>
					<el-table-column prop="last30PerAmount" label="30天单桩收益(元)" min-width="150">
						<template slot-scope="{ row }">
							{{'￥'+util.numFormat(row.last30PerAmount)}}
						</template>
					</el-table-column>
					<el-table-column prop="hisPerAmount" label="历史单桩收益(元)" min-width="130">
						<template slot-scope="{ row }">
								{{'￥'+ util.numFormat(row.hisPerAmount)}}
						</template>
					</el-table-column>
                    <el-table-column prop="incomeHis" label="商户号" min-width="150">
						<template slot-scope="{ row }">
								{{ row.subMchId || '-' }}
						</template>
					</el-table-column>
					<el-table-column label="创建时间"  width="160">
						<template slot-scope="{ row }">
							<span>{{ row.createTimeText || "-" }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="操作" width="140" fixed="right">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" content="详情" placement="top">
								<img src="../../assets/icon/option-detail.png" class="a-wh-16"  @click="toFranchiseeDetail(scope.row)" />
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="修改" placement="top" v-if="scope.row.status==2||scope.row.status==3">
								<img src="../../assets/icon/edit.png" class="a-wh-16 a-ml-12" @click="toFranchiseeDetail(scope.row)" />
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="删除" placement="top">
								<img src="../../assets/icon/option-refuse.png" class="a-wh-16 a-ml-12" @click="delAgency(scope.row)" />
							</el-tooltip>
                            <el-tooltip class="item" effect="dark" content="设置客诉通知人" placement="top" v-if="scope.row.serviceProfit"> 
								<img src="../../assets/icon/option-complaint.png" 
                                    class="a-wh-16 a-ml-12" @click="setCompalintUser(scope.row)" />
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<template slot="footerLabel" slot-scope="scope">
					<div class="a-flex-rfsc" v-if="scope.val != -1">
						<span class="a-fs-12 a-c-normal">筛选出</span>
						<span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{ scope.val }}</span>
						<span class="a-fs-12 a-c-normal">个加盟商</span>
					</div>
				</template>
			</le-pagview>
		</el-card>
        <compalint-user-set ref="complaintUserSet"></compalint-user-set>
	</div>
</template>

<script>
import util from "../../utils/util.js";
import compalintUserSet from './child/compalint-user-set.vue';
export default {
    components: { compalintUserSet },
	data() {
		return {
			util:util,
			pageParam: {
				url: this.$Config.apiUrl.getAgencyList,
				method: "post",
				params: {
					searchKey: "",//加盟商名称
                    queryCompanyId: '',//加盟商id
                    companyType: '',//加盟类型
					type: 0,
					provinceCode: "",
					cityCode: "",
					districtCode: "",
					startTime: "",
					endTime: "",
				},
				freshCtrl: 1,
			},
			optionsProject: [{value:0,label:'直属代理'},{value:1,label:'间接代理'},{value:2,label:'签署中'},{value:3,label:'变更中'}],//加盟商字典
			tableData: [],//数据列表
            optionsCompanyType: [],//加盟商类型字典
		}
	},
	created() {
        this.$getDic('companyType','select').then(res=>{ this.optionsCompanyType = res; })
	},
	activated () {
		this.pageParam.freshCtrl++;
	},
	methods: {
		//获取列表
		setTableData(data) {
			this.tableData = data;
		},
		//确认筛选
		handlerSearch() {
			this.$refs['franchisee'].pageNum = 1
			this.pageParam.freshCtrl++;
		},
		//重置筛选表单
		handlerRest() {
			this.pageParam.params = {
				searchKey: "",//加盟商名称
                queryCompanyId: '',//加盟商id
				type: 0,
                companyType: '',
				provinceCode: "",
				cityCode: "",
				districtCode: "",
				startTime: "",
				endTime: "",
			};
			this.handlerSearch()
		},
		//删除加盟商
		delAgency(data){
			this.$confirm('是否删除该加盟商?', '温馨提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(_=>{
				this.$Axios._get({
					url:this.$Config.apiUrl.delAgency,
					method: "get",
					params: {
						agencyCompanyId : data.companyId
					}
				}).then(res=>{
					if(res.result.code == 0){
                    this.$message.success('操作成功')
                    this.pageParam.freshCtrl++; // 重新请求表格数据
                }else{
                    this.$message.error(res.result.message)
                }
				})
			}).catch(_=>{})
		},
		//添加加盟商
		handlerAddProject() {
			this.$router.push({
				path: '/franchisee/franchisee-add'
			})
		},
        // 是否开启套餐权限
        changeMonthCardAuth (datas, index) {
            if(datas.monthCardAuth == 1){
                this.$confirm('商户套餐权限开启后无法关闭，是否确定开启该商户套餐权限？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.addFinanceMonthCardAuth,
                        params: {
                            id: datas.companyId,
                            monthCardAuth: datas.monthCardAuth
                        }
                    }).then((res) => {
                        this.$message.success('操作成功')
                        this.pageParam.freshCtrl++;
                    }).catch((error) => {
                        this.$message.error(error);
                    })
                }).catch(_=>{
                    this.tableData[index].monthCardAuth = this.tableData[index].monthCardAuth == 0 ? 1 : 0
                })
            }else{
                this.$confirm('商户套餐权限开启后无法关闭。', '温馨提示', {
                    confirmButtonText: '我知道了',
                    type: 'warning',
                    showCancelButton: false
                }).then(_=>{
                    this.tableData[index].monthCardAuth = this.tableData[index].monthCardAuth == 0 ? 1 : 0
                }).catch(_=>{
                    this.tableData[index].monthCardAuth = this.tableData[index].monthCardAuth == 0 ? 1 : 0
                })
            }
            
        },
        // 是否开启独立钱包
        changeServiceProfit (datas, index) {
            if(datas.signState == 1 && datas.serviceProfit == 0){
                this.$confirm('商户独立钱包微信测审核通过后无法关闭。', '温馨提示', {
                    confirmButtonText: '我知道了',
                    type: 'warning',
                    showCancelButton: false
                }).then(_=>{
                    this.tableData[index].serviceProfit = this.tableData[index].serviceProfit == 0 ? 1 : 0
                }).catch(_=>{
                    this.tableData[index].serviceProfit = this.tableData[index].serviceProfit == 0 ? 1 : 0
                })
            }else{
                let text
                if(datas.serviceProfit == 0) {
                    text = '是否确定关闭该商户独立钱包？'
                }else{
                    text = '商户独立钱包微信测审核通过后无法关闭，是否确定开启该商户独立钱包？'
                }
                this.$confirm(text, '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.merchantOpenServiceProfit,
                        params: {
                            id: datas.companyId,
                            serviceProfit: datas.serviceProfit
                        }
                    }).then((res) => {
                        this.$message.success('操作成功')
                        if(datas.serviceProfit == 1){
                            this.setCompalintUser(this.tableData[index])
                        }
                        this.pageParam.freshCtrl++;
                    }).catch((error) => {
                        this.tableData[index].serviceProfit = this.tableData[index].serviceProfit == 0 ? 1 : 0
                        this.$message.error(error);
                    })
                }).catch(_=>{
                    this.tableData[index].serviceProfit = this.tableData[index].serviceProfit == 0 ? 1 : 0
                })
            }
        },
		//跳转详情
		toFranchiseeDetail (datas) {
			if(datas.status==2){
				this.$router.push({
					path: '/franchisee/franchisee-contract?code='+datas.companyId
				})
			}else if(datas.status==3){
				this.$router.push({
					path: '/franchisee/franchisee-change?code='+datas.companyId
				})
			}else{
				this.$router.push({
					path: '/franchisee/franchisee-detail?code='+datas.companyId
				})
			}
			
		},
        setCompalintUser (datas) {
            this.$refs['complaintUserSet'].companyId = datas.companyId
            this.$refs['complaintUserSet'].dialogVisible = true
        }
	}
}
</script>

<style lang="scss" scoped>
	.mgl150 {
		margin-left: 150px;
	}
	/deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>